"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CarouselIndicators = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CarouselIndicators = function CarouselIndicators(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      activeIndex = _ref.activeIndex,
      onSelect = _ref.onSelect,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? '' : _ref$className;
  return items.length > 1 ? _react.default.createElement("ol", {
    className: (0, _classnames.default)("carousel-indicators", className)
  }, items.map(function (item, index) {
    return _react.default.createElement("li", {
      className: (0, _classnames.default)({
        'active': activeIndex === index
      }),
      onClick: function onClick(e) {
        e.preventDefault();
        onSelect(index);
      },
      key: index
    });
  })) : null;
};

exports.CarouselIndicators = CarouselIndicators;
CarouselIndicators.propTypes = {
  items: _propTypes.default.array.isRequired,
  activeIndex: _propTypes.default.number,
  onSelect: _propTypes.default.func.isRequired,
  className: _propTypes.default.string
};