import React from 'react'
import PropTypes from 'prop-types'
import {Link, /*ImageLoader, */Loader, ResponsiveProvider} from 'smarties-cms/dist/components/common'
import {withResponsiveContext} from "smarties-cms/dist/components/common/ResponsiveProvider";
import {findValueBasedOnSizeLayout} from 'smarties-cms/dist/components/common/Responsive'

import {Carousel} from '../carousel'
import {DynamicComponent} from 'smarties-cms/dist/utils'
import {listRequestHelper} from "smarties-cms/dist/connect";

@listRequestHelper({
  resource: "/blog/posts/list"
})
@withResponsiveContext()
class BlogPostsList extends React.PureComponent {
  static propTypes ={
    onFetchList: PropTypes.func,
    data: PropTypes.array,
    loading: PropTypes.bool,
    current: PropTypes.string,
  }

  state ={
    size: findValueBasedOnSizeLayout(this.props.current, {
      "lg-down": 2,
      "xl": 3
    }, 2)
  }

  componentDidMount() {
    this.props.onFetchList()
  }

  componentDidUpdate({ current }) {
    if (this.props.current !== current) {
      const size = findValueBasedOnSizeLayout(this.props.current, {
        "lg-down": 2,
        "xl": 3
      }, this.state.size)

      this.setState({
        size
      })
    }
  }

  render() {
    const {
      loading,
      data = []
    } = this.props

    const nextData = data.slice(0, this.state.size)

    return (
      <Loader className="subscriptions-content-widget-item" fetching={loading}>
        <div className="subscriptions-content-widget-item-body">
          <DynamicComponent
            data={nextData}
            className={`blog-post-size-${this.state.size}`}
            componentName="BlogPostsList"/>
          {
            !loading && (<div className="subscriptions-content-widget-actions text-center">
              <Link href={"/subscriptions/overview"} className="btn btn-primary btn-lg mx-auto mt-5">
                Got to subscriptions
              </Link>
            </div>)
          }
        </div>
      </Loader>)
  }
}


class HomeContent extends React.PureComponent {
  static propTypes = {
    // Carousel items
    carousel: PropTypes.array,
    products: PropTypes.array,
    subscriptions: PropTypes.array,
  }

  render() {
    const {
      carousel,
      products,
      subscriptions = []
    } = this.props


    return (<React.Fragment>
      <Carousel items={carousel.items}/>
      <ResponsiveProvider>
      {
        subscriptions.length > 0 ? (<div className="subscriptions-content-widget">
            <div className="subscriptions-content-widget-header">
              <h1 className="subscriptions-content-widget-title">Your personal content</h1>
              <DynamicComponent
                icon="fas fa-boxes"
                attributes={{
                  subscriptions
                }}
                componentName="SubscriptionMenuInfo"/>
            </div>
            <div className="subscriptions-content-widget-item-list">
              <BlogPostsList/>
            </div>
          </div>) :
          (
            <div className="subscriptions-wrapper"><DynamicComponent componentName="SubscriptionsHomeWidget"/></div>)
      }
      </ResponsiveProvider>
{/*
      <div className="partner-program-widget-wrapper">
        <div className="partner-program-widget">
          <ImageLoader src="images/partner_home.png"/>
          <div className="partner-program-widget-body">
            <h1 className="partner-program-widget-title">
              Partner program
            </h1>
            <p className="partner-program-widget-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <Link href="account/partner" className="btn btn-partner-program-widget">
              Join partner program
            </Link>
          </div>
        </div>
      </div>
*/}
      <div className="popular-products-wrapper">
        {
          products.map((product, index) => (<div
            key={index}
            className="popular-products">
            <DynamicComponent
              {...product}
              componentName="PopularProducts"
            />
          </div>))
        }
      </div>
    </React.Fragment>)
  }
}

export default HomeContent
