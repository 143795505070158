import React from 'react'
import ProductGallerySection from 'smarties-cms/dist/addon/Catalog/ProductGallerySection'
import ProductGallery from './ProductGallery'

export default function (props) {
  return (<ProductGallerySection
    {...props}
    galleryComponent={ProductGallery}
  />)
}
