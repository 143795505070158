"use strict";

var _utils = require("smarties-cms/dist/utils");

var _react = _interopRequireDefault(require("react"));

var _components = require("./components");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _utils.register)({
  'init_ga': function init_ga(props) {
    return _react.default.createElement(_components.GaController, props);
  },
  EventLink: _components.EventLink
});