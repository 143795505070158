"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactChartjs = _interopRequireDefault(require("react-chartjs"));

var _BaseChart = require("./BaseChart");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BarChart = (0, _BaseChart.chartFactory)(_reactChartjs.default.Bar, 'chart-bar-chart');
var _default = BarChart;
exports.default = _default;