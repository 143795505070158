import React from 'react'
import PropTypes from 'prop-types'
import {Gallery, GalleryDetailedImage} from 'smarties-cms/dist/components/gallery'
import {Lightbox, LightboxLink} from 'smarties-cms/dist/components/lightbox'

const Detailed = ({id, ...props}) =>
  (<LightboxLink item={id} tag={GalleryDetailedImage} {...props}/>)

const ThumbnailsList = ({children}) => {
  const right = children.splice(0, 4)

  const Empty = () => (<div className="gallery-thumbnail empty">
    <div className="gallery-thumbnail-body">
      <i className="fas fa-fw fa-expand-wide d-block icon mb-1"/>
      Gallery
    </div>
  </div>)

  return (<React.Fragment>
    <div className="gallery-thumbnails-list right-aside">
      {
        right
      }
      {
        right.length < 4 && (<Empty/>)
      }
    </div>
    <div className="gallery-thumbnails-list bottom-aside">
      {
        children
      }
      {
        right.length > 4 && (<Empty/>)
      }
    </div>
  </React.Fragment>)
}

ThumbnailsList.propTypes = {
  children: PropTypes.arrayOf(React.node)
}

export default class ProductGallery extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
  }

  convertToLightboxItems = (items) => items.map((item) => {
    let video = undefined
    if (item.video) {
      const videoId = item.video.replace(/.+\?v=/,'')
      video = {
        id: videoId,
        src: item.video
      }
    }

    return {
      id: item.id,
      name: item.title || item.name,
      description: item.content,
      publishedAt: '2019-10-08T10:49:58+00:00',
      thumbnail: {
        src: item.detailed,
        placeholder: {
          bg: item.dominantColor
        }
      },
      video
    }
  })

  render() {
    const {items} = this.props
    return (<Lightbox items={this.convertToLightboxItems(items)}>
      <Gallery
        {...this.props}
        pause={false}
        className="product-gallery"
        detailedComponent={Detailed}
        thumbnailProps={{
          placeholder: {
            width: 320,
            height: 320 * 9 / 16
          },
          placeholderClass: 'placeholder-16-to-9'
        }}
        thumbnailsListComponent={ThumbnailsList}
      />
    </Lightbox>)
  }
}
