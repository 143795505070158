"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SupportTicketView", {
  enumerable: true,
  get: function get() {
    return _SupportTicketView.default;
  }
});
Object.defineProperty(exports, "SupportTicketCommit", {
  enumerable: true,
  get: function get() {
    return _SupportTicketCommit.default;
  }
});

var _AsyncComponent = require("smarties-cms/dist/utils/AsyncComponent");

var _SupportTicketView = _interopRequireDefault(require("./SupportTicketView"));

var _SupportTicketCommit = _interopRequireDefault(require("./SupportTicketCommit"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }