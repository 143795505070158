import React from 'react'
// import PropTypes from 'prop-types'
import { CardAction } from 'smarties-cms/dist/addon/Catalog/CardAction'

export default class ProductCardActions extends React.PureComponent {
  render = () => (<div className="category-actions">
      <CardAction name="Like" className="fa-fw far fa-thumbs-up"/>
      <CardAction name="Review" className="fa-fw far fa-comment"/>
      <CardAction name="Share" className="fa-fw far fa-share"/>
      <CardAction name="Favorite" className="fa-fw far fa-bookmark"/>
    </div>)
}