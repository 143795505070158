import React from 'react'
import PropTypes from 'prop-types'
import CatalogView from './CatalogView'
import {Link} from 'smarties-cms/dist/components/common'
import {List} from 'smarties-cms/dist/components/list'
import {listRequestHelper} from 'smarties-cms/dist/connect'
import classnames from 'classnames'

@listRequestHelper()
class PopularProducts extends React.PureComponent {
  static propTypes = {
    category: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
    showDescription: PropTypes.bool,
    showLink: PropTypes.bool,
    linkIcon: PropTypes.string,
  }

  static defaultProps = {
    linkIcon: 'fas fa-chevron-right',
  }

  render() {
    const {
      category,
      showDescription,
      showLink,
      linkIcon
    } = this.props

    return (<div className={classnames("popular-products-inner-wrapper", {
      'show-description': showDescription,
      'show-link': showLink
    })}>
      <div className="popular-products-title">
        <h1>{category.name}</h1>
      </div>
      {
        showDescription && (<p className="popular-products-description">
          {category.description}
        </p>)
      }
      {
        showLink && (<div className="popular-products-link">
          <Link href={category.link} className="btn btn-link">
            <span className="btn btn-icon">
              <i className={linkIcon}/>
            </span>
            <span className="btn btn-text">
              See all
            </span>
          </Link>
        </div>)
      }
      <CatalogView
        {...this.props}
        limits={[3]}
        listComponent={List}
        listClassName="products-list"
        gridSelectorComponent={null}
        showDescription={true}
        scrollToElement={false}
        showFilters={false}
        allowFetchingNext={false}/>
    </div>)
  }
}

export default PopularProducts
