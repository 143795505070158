"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AsideGroupContent = AsideGroupContent;
exports.AsideGroupTitle = AsideGroupTitle;
exports.default = void 0;

require("core-js/modules/es6.object.assign");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.object.keys");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function AsideGroup(_ref) {
  var children = _ref.children;
  return _react.default.createElement("div", {
    className: "support-center-aside-group"
  }, children);
}

function AsideGroupTitle(_ref2) {
  var children = _ref2.children;
  return _react.default.createElement("div", {
    className: "support-center-aside-group-title"
  }, children);
}

function AsideGroupContent(_ref3) {
  var children = _ref3.children,
      _ref3$tag = _ref3.tag,
      Tag = _ref3$tag === void 0 ? 'div' : _ref3$tag,
      props = _objectWithoutProperties(_ref3, ["children", "tag"]);

  return _react.default.createElement(Tag, _extends({
    className: "support-center-aside-group-content"
  }, props), children);
}

var commonProps = {
  children: _propTypes.default.node
};
AsideGroup.propTypes = commonProps;
AsideGroupContent.propTypes = commonProps;
AsideGroupTitle.propTypes = commonProps;
var _default = AsideGroup;
exports.default = _default;