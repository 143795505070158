"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openSupportTicketInChat = openSupportTicketInChat;
exports.openSupportTicketCustomer = openSupportTicketCustomer;

var _utils = require("reactstrap/lib/utils");

function openSupportTicketInChat(id) {
  if (_utils.canUseDOM) {
    document.documentElement.dispatchEvent(new CustomEvent('support-center-open', {
      detail: id
    }));
  }
}

function openSupportTicketCustomer(id) {
  if (_utils.canUseDOM) {
    document.documentElement.dispatchEvent(new CustomEvent('support-center-open-customer', {
      detail: id
    }));
  }
}