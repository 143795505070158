"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.assign");

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _common = require("smarties-cms/dist/components/common");

var _reactstrap = require("reactstrap");

var _utils = require("smarties-cms/dist/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var BlogPostAttachment = function BlogPostAttachment(props) {
  var title = props.title,
      url = props.url,
      icon = props.icon,
      size = props.size,
      mime = props.mime,
      lock = props.lock;
  var id = (0, _react.useRef)((0, _utils.uniqId)('blog-post-attachment-lock'));
  return _react.default.createElement(_common.Link, {
    href: url,
    blank: true,
    "data-mime": mime,
    className: "blog-post-attachment nav-link"
  }, _react.default.createElement("span", {
    className: "blog-post-attachment-icon"
  }, _react.default.createElement("i", {
    className: "fas fa-fw ".concat(icon)
  })), _react.default.createElement("span", {
    className: "blog-post-attachment-title"
  }, title), _react.default.createElement("span", {
    className: "blog-post-attachment-size"
  }, size), lock && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("span", {
    className: "blog-post-attachment-lock",
    id: id.current
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-lock"
  })), _react.default.createElement(_reactstrap.UncontrolledTooltip, {
    target: id.current,
    placement: "bottom"
  }, "This content is not available in your subscription")));
};

BlogPostAttachment.propTypes = {
  title: _propTypes.default.string,
  url: _propTypes.default.string,
  icon: _propTypes.default.string
};

var BlogPostAttachments = function BlogPostAttachments(props) {
  var _props$attachments = props.attachments,
      attachments = _props$attachments === void 0 ? [] : _props$attachments;

  if (attachments.length === 0) {
    return null;
  }

  return _react.default.createElement("div", {
    className: "blog-post-attachment-list-wrapper blog-post-wrapper-aside-item"
  }, _react.default.createElement("h4", {
    className: "blog-post-wrapper-aside-item-title"
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-download text-primary"
  }), "Attachments"), _react.default.createElement("div", {
    className: "blog-post-attachment-list nav"
  }, attachments.map(function (attachment, index) {
    return _react.default.createElement(BlogPostAttachment, _extends({
      key: index
    }, attachment));
  })));
};

BlogPostAttachments.propTypes = {
  attachments: _propTypes.default.arrayOf(_propTypes.default.shape(BlogPostAttachment.propTypes))
};
var _default = BlogPostAttachments;
exports.default = _default;