"use strict";

var _react = _interopRequireDefault(require("react"));

var _utils = require("smarties-cms/dist/utils");

var _component = require("./component");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _utils.register)({
  ContactLayout: [_component.ContactLayout, true]
});