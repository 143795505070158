import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { RegisteredProductsAside } from 'smarties-cms/dist/addon/RegisteredProducts/client/components/account'
import {ImageLoader, Placeholder} from 'smarties-cms/dist/components/common'

export const AccountInfo = (props) => {
  const {
    user,
    className
  } = props

  const hasThumbnail = typeof user.thumbnail !== "undefined"

  return (<div className={classnames("user-account-info", className, {
    "thumbnail": hasThumbnail
  })}>
    <div className={classnames("user-account-icon", {
      "thumbnail": hasThumbnail
    })}>
      {
        hasThumbnail && (<ImageLoader {...user.thumbnail}/>)
      }
      {
        !hasThumbnail && (<Placeholder placeholder={{
          width: 64,
          height: 64,
          background: 'transparent'
        }}>
          <i className="fas fa-fw fa-user-custom"/>
        </Placeholder>)
      }
    </div>
    <div className="user-account-name">
      {user.fullname}
    </div>
    <div className="user-account-type">
      {user.role}
    </div>
  </div>)
}
AccountInfo.propTypes = {
  user: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default class AsideAccountInfo extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object
  }

  render() {
    const {user} = this.props
    return (<React.Fragment>
      <AccountInfo user={user}/>
      <RegisteredProductsAside/>
    </React.Fragment>)
  }
}
