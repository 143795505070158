"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RegisteredProductsList", {
  enumerable: true,
  get: function get() {
    return _RegisteredProductsList.default;
  }
});
Object.defineProperty(exports, "RegisteredProductDetails", {
  enumerable: true,
  get: function get() {
    return _RegisteredProductDetails.default;
  }
});

var _RegisteredProductsList = _interopRequireDefault(require("./RegisteredProductsList"));

var _RegisteredProductDetails = _interopRequireDefault(require("./RegisteredProductDetails"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }