import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Link, Responsive, ResponsiveProvider} from 'smarties-cms/dist/components/common'
import {CSSTransition as Transition} from 'react-transition-group'
import {DynamicComponent} from 'smarties-cms/dist/utils'
import HeaderNotifications from './HeaderNotifications'
import {UserMenuConnected as UserMenu} from 'smarties-cms/dist/client/user'
import {UserDropdownMenu} from '../account'
import Portal from 'reactstrap/lib/Portal'
import HeaderCatalog from "./HeaderCatalog";
import HeaderCart from './HeaderCart'

export default class Header extends React.PureComponent {

  static propTypes = {
    menu: PropTypes.array,
    openNavbarSubmenuClass: PropTypes.string
  }

  static defaultProps = {
    menu: [],
    openNavbarSubmenuClass: 'navbar-open'
  }

  state = {
    openSubmenu: false,
    isMobileOpen: false
  }

  constructor(props) {
    super(props)

    this.submenuContentRef = React.createRef()
  }

  toggleBodyClass = () => {
    const classes = document.body.classList
    if (this.state.openSubmenu) {
      if (!classes.contains(this.props.openNavbarSubmenuClass)) {
        classes.add(this.props.openNavbarSubmenuClass)
      }
    } else if (!this.state.openSubmenu) {
      classes.remove(this.props.openNavbarSubmenuClass)
    }
  }

  componentDidUpdate(prevProps, {openSubmenu, isMobileOpen}) {
    if (openSubmenu !== this.state.openSubmenu) {
      document.dispatchEvent(new CustomEvent('pause', {
        detail: this.state.openSubmenu
      }))
    }

    if (isMobileOpen !== this.state.isMobileOpen) {
      document.body.classList.toggle('body-no-overflow');
    }
  }

  openSubmenu = (node) => {
    if (!node.children || node.discr !== 'catalogrootmenuitem') {
      return null
    }
    return (e) => {
      e.preventDefault();

      this.setState({
        activeNode: node,
        openSubmenu: !this.state.openSubmenu,
        categoryFilter: node.children[0].category
      })
    }
  }

  closeSubmenu = (e) => {
    const {target} = e
    const {current: root} = this.submenuContentRef
    const checkers = [
      ...root.querySelectorAll('.navbar-submenu-filters .nav-item'),
      ...root.querySelectorAll('.list-wrapper-item'),
      root.querySelector('form')
    ]

    const state = checkers.reduce((state, element) => state || element.contains(target), false)
    if (!state) {
      this.setState({
        openSubmenu: false
      })
    }
  }

  onToggleMobile = () => this.setState({
    isMobileOpen: !this.state.isMobileOpen
  })


  render() {
    const {menu, ...props} = this.props
    const {openSubmenu, activeNode} = this.state

    return (<ResponsiveProvider>
      <nav className="navbar bg-danger">
        <Responsive showOn="lg-up">
          <a className="navbar-brand" href={props.homeUrl}>
            <img src={props.logo} className="img-fluid"/>
          </a>
          <div className="nav navbar-menu flex-row" role="navigation">
            {
              menu.map(node => (<li className="nav-item" key={node.id}>
                <Link
                  active={openSubmenu && activeNode ? activeNode.id === node.id : node.active}
                  href={node.link}
                  className="nav-link"
                  onClick={this.openSubmenu(node)}>
                  {node.name}
                </Link>
              </li>))
            }
          </div>
          <div className="nav navbar-actions flex-row">
            <DynamicComponent
              {...props}
              componentName="Navbar"
              className="nav-item"
              renderChildrenAsArray
              fallbackComponent={({children}) => children}>
              {
                ({children}) => (<React.Fragment>
                  <HeaderCart/>
                  {children || null}
                  <HeaderNotifications className="nav-item"/>
                  <UserMenu
                    menuComponent={UserDropdownMenu}
                    className="nav-item"/>
                </React.Fragment>)
              }
            </DynamicComponent>
          </div>
        </Responsive>
        <Responsive showOn="md-down">
          <a className="navbar-brand" href={props.homeUrl}>
            <img src={props.logoMobile} className="img-fluid"/>
          </a>
          <div className="nav flex-row" role="navigation">
            <li className="nav-item" onClick={this.onToggleMobile}>
              <span className="nav-link">
                <i className="fas fa-fw fa-bars"/>
              </span>
            </li>
          </div>
          <div className="nav navbar-actions flex-row">
            <DynamicComponent
              {...props}
              componentName="Navbar"
              className="nav-item"
              renderChildrenAsArray
              fallbackComponent={({children}) => children}>
              {
                ({children}) => (<React.Fragment>
                  <HeaderCart layout="badge"/>
                  {children || null}
                  <HeaderNotifications className="nav-item"/>
                  <UserMenu
                    loginStyle="icon"
                    className="nav-item"/>
                </React.Fragment>)
              }
            </DynamicComponent>
          </div>
        </Responsive>
      </nav>
      <Responsive showOn="md-down">
        <Portal>
          <Transition
            timeout={200}
            in={this.state.isMobileOpen}
            classNames="navbar-mobile-menu">
            {
              state => (<aside className={classnames("navbar-mobile-menu", state)}>
                <div className="navbar bg-danger">
                  <nav className="nav">
                    <li className="nav-item" onClick={this.onToggleMobile}>
                      <span className="nav-link">
                        <i className="fas fa-fw fa-times"/>
                      </span>
                    </li>
                  </nav>
                </div>
                <div className="navbar-mobile-nav navbar bg-danger">
                  <nav className="nav flex-column" role="navigation">
                    {
                      menu.map(node => (<li className="nav-item" key={node.id}>
                        <Link
                          active={node.active}
                          href={node.link}
                          className="nav-link">
                          {node.name}
                        </Link>
                      </li>))
                    }
                  </nav>
                </div>
              </aside>)
            }
          </Transition>
        </Portal>
      </Responsive>
      <Responsive showOn="lg-up">
        <Portal>
          <Transition
            classNames="navbar-submenu"
            timeout={100}
            onEntering={this.toggleBodyClass}
            onExiting={this.toggleBodyClass}
            in={openSubmenu}>
            {state => openSubmenu && activeNode && (
              <aside className={classnames("navbar-submenu", state)} onClick={this.closeSubmenu} style={{
                overflowY: 'scroll'
              }}>
                <div className="container-fluid">
                  <div className="row" ref={this.submenuContentRef}>
                    <HeaderCatalog
                      activeNode={activeNode}
                      setCategoryFilter={this.setCategoryFilter}/>
                  </div>
                </div>
              </aside>
            )}
          </Transition>
        </Portal>
      </Responsive>
    </ResponsiveProvider>)
  }
}
