import React from 'react'
import PropTypes from 'prop-types'
import { ProductTabView as ProductTabViewDefault } from 'smarties-cms/dist/addon/Catalog'
import { ImageLoader } from 'smarties-cms/dist/components/common'

export default class ProductTabView extends React.PureComponent {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  render () {
    const {
      product: {
        name,
        branding
      }
    } = this.props

    return (<React.Fragment>
      <header className="product-header">
        {
          typeof branding !== 'undefined' && branding.thumbnail !== null && (<div className="product-branding">
              <ImageLoader {...branding.thumbnail} className="product-branding-image" title={branding.name}/>
          </div>)
        }
        <h1 className="product-title">{name}</h1>
      </header>
      <ProductTabViewDefault
        {...this.props}
        showNav={false}/>
    </React.Fragment>)
  }
}
