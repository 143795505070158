import React from 'react'
import { SearchWrapperConnected } from 'smarties-cms/dist/addon/Catalog/SearchWrapper'
import { CatalogAsideMenu } from 'smarties-cms/dist/addon/Catalog'

const SearchWrapper = (props) =>
  (<React.Fragment>
    <CatalogAsideMenu {...props}/>
    <SearchWrapperConnected
      {...props}
      scrollToContainer={() => {
        document.querySelector('.article-with-aside').classList.toggle('hide-aside')
      }}
    />
  </React.Fragment>)

export default SearchWrapper
