"use strict";

var _react = _interopRequireDefault(require("react"));

var _utils = require("smarties-cms/dist/utils");

var _store = require("smarties-cms/dist/store");

var _reactRedux = require("react-redux");

var _ProductGallerySection = _interopRequireDefault(require("./ProductGallerySection"));

var _store2 = require("./store");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VideoConnected = function VideoConnected(props) {
  return _react.default.createElement(_reactRedux.Provider, {
    store: (0, _store.getStore)()
  }, _react.default.createElement(_store2.VideoConnected, props));
};

(0, _utils.register)({
  Video: VideoConnected,
  VideoTestimonialCarousel: function VideoTestimonialCarousel(props) {
    return _react.default.createElement(_reactRedux.Provider, {
      store: (0, _store.getStore)()
    }, _react.default.createElement(_store2.VideoTestimonialCarouselContainer, props));
  },
  ProductGallerySection: [_ProductGallerySection.default, true]
});