import React from 'react'
import PropTypes from 'prop-types'
import {
  ArticleLayout,
  ArticleAsideToggler,
  ArticleAside,
  ArticleAsideInnerWrapper,
  ArticleBody,
  ArticleBreadcrumbs
} from 'smarties-cms/dist/components/article'
import {Link} from 'smarties-cms/dist/components/common'
import {Breadcrumb, BreadcrumbItem} from 'reactstrap'
import {ProductNav} from 'smarties-cms/dist/addon/Catalog'
import ProductTabView from './ProductTabView'
import {updateRequestHelper} from 'smarties-cms/dist/connect'

@updateRequestHelper()
class ProductLayout extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.array
  }

  constructor(props) {
    super(props)

    this.state = ProductLayout.getStateFromProps(props)
  }

  static getStateFromProps({categories}) {
    return {
      breadcrumbs: ProductLayout.findBreadcrumbs(categories)
    }
  }

  static findBreadcrumbItem(carry, {active, items = [], name, link}) {
    if (active) {
      carry.push({link, name})

      if (items.length) {
        return items.reduce(ProductLayout.findBreadcrumbItem, carry)
      }
    }
    return carry
  }

  static findBreadcrumbs(categories) {
    return categories.reduce(ProductLayout.findBreadcrumbItem, [])
  }

  renderBreadcrumbs = () => {
    const {breadcrumbs} = this.state

    return (<Breadcrumb>
      <BreadcrumbItem active={breadcrumbs.length === 0}>
        <Link href="/catalog">Equipment</Link>
      </BreadcrumbItem>
      {
        breadcrumbs.map(({name, link}, index) => (<BreadcrumbItem key={index} active={index === breadcrumbs.length - 1}>
          <Link href={link}>{name}</Link>
        </BreadcrumbItem>))
      }
    </Breadcrumb>)
  }

  render() {
    return (<ArticleLayout withAside withBreadcrumbs className="product-article" fluid>
      <ArticleAside sticky className="product-nav">
        <ArticleAsideInnerWrapper>
          <div className="nav flex-column mb-3 nav-product-main">
            <div className="nav-item go-back">
              <a href="#" onClick={(e) => {
                e.preventDefault()
                history.go(-1)
              }}
                 className="nav-link">
                <i className="far fa-arrow-left fa-fw mr-2"/>back
              </a>
            </div>
          </div>
          <ProductNav {...this.props}/>
        </ArticleAsideInnerWrapper>
        <ArticleAsideToggler/>
      </ArticleAside>
      <ArticleBreadcrumbs>
        {this.renderBreadcrumbs()}
      </ArticleBreadcrumbs>
      <ArticleBody>
        <ProductTabView {...this.props}/>
      </ArticleBody>
    </ArticleLayout>)
  }
}

export default ProductLayout
