"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardShareAction = exports.CategoryIdentifierAction = exports.CardActions = exports.CardAction = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.replace");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactstrap = require("reactstrap");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CardAction = function CardAction(_ref) {
  var children = _ref.children,
      className = _ref.className,
      _ref$onClick = _ref.onClick,
      onClick = _ref$onClick === void 0 ? function () {} : _ref$onClick;
  var id = name.replace(/[^A-z]+/, '').toLowerCase();
  return _react.default.createElement("div", {
    className: "category-action-item"
  }, _react.default.createElement("div", {
    className: "category-action"
  }, _react.default.createElement("span", {
    id: id,
    onClick: onClick,
    className: (0, _classnames.default)("category-icon", className)
  }, children), _react.default.createElement(_reactstrap.UncontrolledTooltip, {
    target: id,
    placement: "bottom"
  }, name)));
};

exports.CardAction = CardAction;
CardAction.propTypes = {
  className: _propTypes.default.string,
  name: _propTypes.default.name,
  onClick: _propTypes.default.func,
  children: _propTypes.default.any
};

var CardActions = function CardActions(_ref2) {
  var children = _ref2.children;
  return _react.default.createElement("div", {
    className: "category-actions flex-row"
  }, children);
};

exports.CardActions = CardActions;
CardActions.propTypes = {
  children: _propTypes.default.any
};

var CategoryIdentifierAction = function CategoryIdentifierAction(_ref3) {
  var name = _ref3.name,
      label = _ref3.label;
  var identifier = name.split(' ');
  var firstLetters = identifier.map(function (name) {
    return name[0].toLowerCase();
  }).slice(0, 2);

  if (firstLetters.length === 1) {
    firstLetters.push(name[1]);
  }

  firstLetters[0] = firstLetters.toUpperCase();
  return _react.default.createElement(CardAction, {
    className: "category-icon-".concat(label)
  }, firstLetters.join());
};

exports.CategoryIdentifierAction = CategoryIdentifierAction;
CategoryIdentifierAction.propTypes = {
  name: _propTypes.default.string,
  label: _propTypes.default.string
};

var CardShareAction = function CardShareAction() {
  return _react.default.createElement(CardAction, null, _react.default.createElement("i", {
    className: "fa fa-share-alt"
  }));
};

exports.CardShareAction = CardShareAction;