"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _react = _interopRequireDefault(require("react"));

var _common = require("smarties-cms/dist/components/common");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BlogPostTagsList = function BlogPostTagsList(props) {
  var _props$tags = props.tags,
      tags = _props$tags === void 0 ? [] : _props$tags,
      _props$text = props.text,
      text = _props$text === void 0 ? false : _props$text;

  if (tags.length === 0) {
    return null;
  }

  return _react.default.createElement("div", {
    className: "blog-post-tags-list-wrapper  blog-post-wrapper-aside-item"
  }, _react.default.createElement("h4", {
    className: "blog-post-wrapper-aside-item-title"
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-tags text-danger"
  }), "Article Tags"), _react.default.createElement("nav", {
    className: "blog-post-tags-list nav"
  }, tags.map(function (tag) {
    return _react.default.createElement(_common.Link, {
      href: !text ? tag.uri : undefined,
      className: "nav-link"
    }, tag.name);
  })));
};

var _default = BlogPostTagsList;
exports.default = _default;