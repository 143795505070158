import React from 'react'
import PropTypes from 'prop-types'
import {
  Carousel as DefaultCarousel,
  CarouselIndicatorsWithPreview
} from 'smarties-cms/dist/addon/Carousel'

export default class Carousel extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array
  }

  static defaultProps = {
    items: []
  }

  state = {
    pause: false
  }

  onPauseToggle = ({ detail }) => this.setState({
    pause: detail
  })

  componentDidMount() {
    document.addEventListener('pause', this.onPauseToggle)
  }

  componentWillUnmount() {
    document.removeEventListener('pause', this.onPauseToggle)
  }

  render () {
    const { items } =this.props

    return (<DefaultCarousel
      pause={this.state.pause}
      indicatorsComponent={CarouselIndicatorsWithPreview}
      displaySeeAll
      items={items.map(item => ({
        ...item,
        placeholder: {
          width: 1920,
          height: 900
        }
      }))}/>)
  }
}
