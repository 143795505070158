"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openCallView = openCallView;

var _utils = require("reactstrap/lib/utils");

function openCallView(callId) {
  if (!_utils.canUseDOM) {
    return;
  }

  document.documentElement.dispatchEvent(new CustomEvent('open-call-view', {
    detail: callId
  }));
}