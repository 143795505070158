"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.videoConnectFactory = exports.VideoTestimonialCarouselContainer = exports.VideoConnected = exports.VideoConnectedGroups = exports.VideoTabPaneFactory = void 0;

require("core-js/modules/es6.object.assign");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.object.keys");

var _react = _interopRequireDefault(require("react"));

var _communication = require("smarties-cms/dist/communication");

var _connect = require("smarties-cms/dist/connect");

var _VideoTestimonialCarousel = require("./VideoTestimonialCarousel");

var _Video = _interopRequireDefault(require("./Video"));

var _VideoTabPane = _interopRequireDefault(require("./VideoTabPane"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _actions2 = function actions(dispatch, _ref) {
  var store = _ref.store;
  return {
    onOpenMediaModal: function onOpenMediaModal() {
      return dispatch(_objectSpread({}, (0, _communication.successFactory)('video', {
        open: true,
        mediaItem: {}
      }), {
        store: store
      }));
    },
    onOpenMediaItem: function onOpenMediaItem(item) {
      return dispatch(_objectSpread({}, (0, _communication.successFactory)('video', {
        open: true,
        mediaItem: item
      }), {
        store: store
      }));
    },
    onCloseMediaModal: function onCloseMediaModal() {
      return dispatch(_objectSpread({}, (0, _communication.successFactory)('video', {
        open: false
      }), {
        store: store
      }));
    }
  };
};

var VideoTabPaneFactory = function VideoTabPaneFactory(props) {
  var Hoc = (0, _connect.listFactory)('rest', "video/group/".concat(props.id), {
    component: _VideoTabPane.default
  });
  return _react.default.createElement(Hoc, props);
};

exports.VideoTabPaneFactory = VideoTabPaneFactory;
var VideoConnectedGroups = (0, _connect.listFactory)('rest', 'video/group', {
  actions: _actions2,
  component: function component(props) {
    return _react.default.createElement(_Video.default, _extends({}, props, {
      tabPaneComponent: function tabPaneComponent(props) {
        return VideoTabPaneFactory(props);
      }
    }));
  }
});
exports.VideoConnectedGroups = VideoConnectedGroups;
var VideoConnected = (0, _connect.connectFactory)('rest', 'video', {
  component: VideoConnectedGroups
});
exports.VideoConnected = VideoConnected;
var VideoTestimonialCarouselContainer = (0, _connect.listFactory)('rest', 'video', {
  actions: _actions2,
  component: _VideoTestimonialCarousel.VideoTestimonialCarousel
});
exports.VideoTestimonialCarouselContainer = VideoTestimonialCarouselContainer;

var videoConnectFactory = function videoConnectFactory(options) {
  return (0, _connect.listFactory)('rest', 'video', _objectSpread({}, options, {
    actions: function actions(dispatch, opt, _actions) {
      var a = _actions2(dispatch, opt);

      var b = _objectSpread({}, _actions, {}, a);

      if (options.actions) {
        return _objectSpread({}, b, {}, options.actions(dispatch, opt, b));
      }

      return b;
    }
  }));
};

exports.videoConnectFactory = videoConnectFactory;