"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductFeatures = exports.ProductFeature = void 0;

require("core-js/modules/es6.object.assign");

require("core-js/modules/es6.function.name");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var ProductFeature = function ProductFeature(_ref) {
  var name = _ref.name,
      value = _ref.value,
      _ref$className = _ref.className,
      className = _ref$className === void 0 ? '' : _ref$className;
  var hasManyValues = Array.isArray(value);
  return _react.default.createElement("div", {
    className: (0, _classnames.default)('product-feature-item', className)
  }, _react.default.createElement("div", {
    className: "product-feature-label"
  }, name), _react.default.createElement("div", {
    className: (0, _classnames.default)("product-feature-value", {
      'product-feature-value-list': hasManyValues
    })
  }, hasManyValues ? value.map(function (val, index) {
    return _react.default.createElement("span", {
      key: index,
      className: "product-feature-value-item"
    }, val);
  }) : value));
};

exports.ProductFeature = ProductFeature;
ProductFeature.propTypes = {
  name: _propTypes.default.string.isRequired,
  value: _propTypes.default.string,
  thumbnail: _propTypes.default.object,
  className: _propTypes.default.string
};

var ProductFeatures = function ProductFeatures(_ref2) {
  var features = _ref2.features,
      className = _ref2.className;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)(className, 'product-features')
  }, features.map(function (item, index) {
    return _react.default.createElement(ProductFeature, _extends({
      key: index
    }, item));
  }));
};

exports.ProductFeatures = ProductFeatures;
ProductFeatures.propTypes = {
  features: _propTypes.default.array,
  className: _propTypes.default.string
};