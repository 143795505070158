"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.string.link");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _common = require("smarties-cms/dist/components/common");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Counter = function Counter(_ref) {
  var data = _ref.data,
      noLabels = _ref.noLabels;
  return _react.default.createElement("div", {
    className: "counter-chart"
  }, data.map(function (_ref2, index) {
    var value = _ref2.value,
        label = _ref2.label,
        link = _ref2.link;
    return _react.default.createElement("div", {
      className: (0, _classnames.default)("counter-item", {
        'zero': Number(value) === 0
      }),
      key: index
    }, !noLabels ? _react.default.createElement(_common.Link, {
      href: link,
      tag: typeof link !== "undefined" && "a" || "div",
      className: "counter-label"
    }, label) : null, _react.default.createElement("div", {
      className: "counter-value"
    }, value));
  }));
};

Counter.propTypes = {
  data: _propTypes.default.array,
  noLabels: _propTypes.default.bool
};
var _default = Counter;
exports.default = _default;