"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ellipsis = require("smarties-cms/dist/components/ellipsis");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VideoInfo = function VideoInfo(_ref) {
  var name = _ref.name,
      description = _ref.description,
      onClose = _ref.onClose;
  return _react.default.createElement("div", {
    className: "video-info-wrapper"
  }, onClose ? _react.default.createElement("div", {
    className: "video-close",
    onClick: onClose
  }) : null, name ? _react.default.createElement("header", {
    className: "video-info"
  }, _react.default.createElement("h1", {
    className: "video-title"
  }, name), _react.default.createElement(_ellipsis.ToggleEllipsis, {
    text: unescape(String(description)),
    maxLines: 1,
    className: "video-description"
  })) : null);
};

VideoInfo.propTypes = {
  // Video/product name
  name: _propTypes.default.string,
  // Video/product description
  description: _propTypes.default.string,
  // Close video modal
  onClose: _propTypes.default.func.isRequired
};
var _default = VideoInfo;
exports.default = _default;