import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'smarties-cms/dist/components/common'
import {CatalogView as DefaultCatalogView} from 'smarties-cms/dist/addon/Catalog/CatalogView'
import {ProductCard} from 'smarties-cms/dist/addon/Catalog'
import classnames from 'classnames'
import {CSSTransition as Transition} from 'react-transition-group'
import ProductCardActions from './ProductCardActions'
import {Preview} from 'smarties-cms/dist/components/wysiwyg'

export class SearchForm extends React.PureComponent {
  static propTypes = {
    onSearchUsing: PropTypes.func.isRequired,
    searchPhrase: PropTypes.string,
    searchTimeout: PropTypes.number,
    target: PropTypes.string
  }

  static defaultProps = {
    searchTimeout: 1000
  }

  state = {
    value: ''
  }

  componentDidUpdate({ searchTimeout }, {value}) {
    if (this.state.value !== value && searchTimeout > 0) {
      this.searchTimeout()
    }
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps({searchPhrase, searchTimeout}) {
    if (this.state.searchPhrase !== searchPhrase && searchTimeout > 0) {
      this.setState({searchPhrase})
    }
  }

  searchTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    this.timeout = setTimeout(() => this.props.onSearchUsing(this.state.value), this.props.searchTimeout)
  }

  onChange = (e) => this.setState({
    value: e.target.value
  })

  onSubmit = (e) => {
    e.preventDefault()

    clearTimeout(this.timeout)
    this.props.onSearchUsing(this.state.value)
  }

  render() {
    const {value} = this.state

    return (<div className="category-search">
      <form
        method="GET"
        className="form search-form"
        onSubmit={this.onSubmit}
        action={this.props.target}>
        <div className="from-group">
          <input name="q"
                 value={value}
                 onChange={this.onChange}
                 type="string"
                 maxLength="1024"
                 className="form-control"
                 placeholder={"search model"}/>
        </div>
      </form>
    </div>)
  }
}

export default class CatalogView extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.array,
    category: PropTypes.object,
    showFilters: PropTypes.bool,
    allowFetchingNext: PropTypes.bool,
    showDescription: PropTypes.bool
  }

  static defaultProps = {
    showFilter: true,
    allowFetchingNext: true
  }

  renderFilters = ({onSearchUsing}) => (<header className="category-header">
    <div className="nav category-filters">
      {
        this.props.categories.slice(0, 2).map(({name, id, link, active}) => (<div className="nav-item" key={id}>
          <Link href={link} className={classnames('nav-link', {active})}>{name}</Link>
        </div>))
      }
    </div>
    <SearchForm onSearchUsing={onSearchUsing}/>
    {this.renderDescription()}
  </header>)

  renderDescription = () => {
    const {category, showDescription} = this.props
    if (!category) {
      return (<div className="my-4"/>)
    }

    return (<div className="category-title text-center">
      <h1 className="text-center my-4">{category.name}</h1>
      {
        showDescription && (<Preview value={category.description}/>)
      }
    </div>)
  }

  render = () => (<DefaultCatalogView
    {...this.props}
    filtersComponent={this.props.showFilters ? this.renderFilters : null}
    disablePagination
    enablePerPageLimitsSelector={false}
    fetchOnMount
    listClassName="products-list"
    recordComponent={(props) => (<Transition
      timeout={250}
      in={props.hovered}
      classNames="card">
      {
        (state) => <ProductCard {...props} className={state}/>
      }
    </Transition>)}
    extraItemProps={{
      actionsComponent: ProductCardActions,
      maxLines: 1,
      placeholder: {
        width: 455,
        height: 256,
        className: 'placeholder-16-to-9'
      }
    }}>
    {
      ({data = [], eof, fetchNextPage}) => this.props.allowFetchingNext && data.length > 0 && !eof && (<div className="list-wrapper-item">
        <Link className="card next-page-card" href="/catalog" onClick={e => {
          e.preventDefault()
          fetchNextPage()
        }}>
          <span className="card-body">Load next</span>
        </Link>
      </div>)
    }
  </DefaultCatalogView>)
}

