import React from 'react'
import PropTypes from 'prop-types'
import {DynamicComponent} from 'smarties-cms/dist/utils'
import {
  ArticleLayout,
  ArticleAside,
  ArticleAsideToggler,
  ArticleBody,
  ArticleBreadcrumbs,
  ArticleHeader,
  ArticleAsideInnerWrapper,
  ArticleForm
} from 'smarties-cms/dist/components/article'
import {Menu} from 'smarties-cms/dist/components/menu'
import {Breadcrumbs} from 'smarties-cms/dist/components/breadcrumbs/Breadcrumbs';
import {AlertWrapperContainer} from 'smarties-cms/dist/components/alert'

import classnames from 'classnames'

class ArticleView extends React.PureComponent {
  static propTypes = {
    menu: PropTypes.array.isRequired,
    article: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      description: PropTypes.string,
      form: PropTypes.shape({
        schema: PropTypes.object,
        uiSchema: PropTypes.object,
        recaptcha: PropTypes.string
      })
    }).isRequired,
    className: PropTypes.string,
    // Article body component
    component: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.func
    ])
  }

  renderBody = ({article}) => (<div dangerouslySetInnerHTML={{__html: article.content}}/>)

  render() {
    const {
      menu,
      article,
      component,
      className,
      ...props
    } = this.props

    return (<React.Fragment>
      <AlertWrapperContainer/>
      <ArticleLayout withAside withBreadcrumbs withHeader fluid className={classnames("article-view", className)}>
        <ArticleAside sticky>
          <ArticleAsideInnerWrapper>
            <Menu
              items={menu}
              level={0}
              className="article-aside-nav"/>
          </ArticleAsideInnerWrapper>
          <ArticleAsideToggler/>
        </ArticleAside>
        <ArticleBody>
          <DynamicComponent
            {...props}
            article={article}
            componentName={component}
            fallbackComponent={this.renderBody}/>
          {
            article.form ? (<ArticleForm {...article.form} className="article-form"/>) : null
          }
        </ArticleBody>
        <ArticleHeader>
          <div className="article-header">
            <h1 className="article-title">{article.title}</h1>
            {
              article.description ? (<p className="article-description">{article.description}</p>) : null
            }
          </div>
        </ArticleHeader>
        <ArticleBreadcrumbs>
          <Breadcrumbs menu={menu} title={article.title}/>
        </ArticleBreadcrumbs>
      </ArticleLayout>
    </React.Fragment>)
  }
}
export default ArticleView
