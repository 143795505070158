"use strict";

var _utils = require("smarties-cms/dist/utils");

var _ShareButtonsWrapper = _interopRequireDefault(require("./ShareButtonsWrapper"));

var _VendorAuthComponents = _interopRequireDefault(require("./VendorAuthComponents"));

var _catalog = require("./client/components/catalog");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _utils.register)({
  ShareButtonsWrapper: _ShareButtonsWrapper.default,
  VendorAuthComponents: _VendorAuthComponents.default,
  ProductAsideComponents: _catalog.ProductShareButtons
});