// Register client
import 'smarties-cms/dist/client/register'
import {register} from 'smarties-cms/dist/utils'

import {AsideAccountInfo} from './account'
import {Header} from './header'
import {Carousel} from './carousel'
import {
  CategoryCardAside,
  ProductTabView,
  CatalogView,
  SearchWrapper,
  ProductGallerySection,
  ProductOverviewSection,
  PopularProducts,
  ProductLayout
} from './catalog'

import {
  ArticleView,
  HomeContent
} from './article'

import FooterBefore from './FooterBefore'

register({
  HomeContent,
  ArticleView,
  AsideAccountInfo,
  Header,
  Carousel,
  PopularProducts,
  CategoryCardAside,
  SearchWrapper,
  CatalogView,
  ProductLayout,
  ProductTabView,
  ProductGallerySection,
  ProductOverviewSection,
  FooterBefore
})
