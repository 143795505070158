"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.components = void 0;

var _react = _interopRequireDefault(require("react"));

var _utils = require("smarties-cms/dist/utils");

var _CatalogAsideMenu = require("../CatalogAsideMenu");

var _ProductCard = require("../ProductCard");

var _SearchWrapper = require("../SearchWrapper");

var _CatalogView = require("../CatalogView");

var _ProductOverviewSection = require("../ProductOverviewSection");

var _ProductRelatedSection = require("../ProductRelatedSection");

var _ProductTestimonials = require("../ProductTestimonials");

var _ProductFeature = require("../ProductFeature");

var _ProductNav = require("../ProductNav");

var _ProductView = require("../ProductView");

var _ProductGallerySection = _interopRequireDefault(require("../ProductGallerySection"));

var _ProductFilesSection = _interopRequireDefault(require("../ProductFilesSection"));

var _ProductViewLayout = _interopRequireDefault(require("../ProductViewLayout"));

var _ProductClassicOverviewSection = _interopRequireDefault(require("../ProductClassicOverviewSection"));

var _CatalogLayout = _interopRequireDefault(require("../CatalogLayout"));

var _ProductTabCollapseContent = _interopRequireDefault(require("../ProductTabCollapseContent"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var components = {
  CatalogAsideMenu: _CatalogAsideMenu.CatalogAsideMenu,
  ProductCard: _ProductCard.ProductCard,
  ProductCardComponent: _ProductCard.ProductCard,
  SearchWrapper: _SearchWrapper.SearchWrapperConnected,
  CatalogView: _CatalogView.CatalogViewFactory,
  ProductFeaturesGroupSection: _ProductFeature.ProductFeaturesGroupSection,
  ProductFeaturesCommonSection: _ProductFeature.ProductFeaturesCommonSection,
  ProductTestimonialsSection: _ProductTestimonials.ProductTestimonialsSection,
  ProductOverviewSection: _ProductOverviewSection.ProductOverviewSection,
  ProductRelatedSection: _ProductRelatedSection.ProductRelatedSection,
  ProductGallerySection: _ProductGallerySection.default,
  ProductNav: _ProductNav.ProductNav,
  ProductTabView: _ProductView.ProductTabView,
  ProductView: _ProductView.ProductView,
  ProductFilesSection: _ProductFilesSection.default,
  ProductViewLayout: _ProductViewLayout.default,
  ProductClassicOverviewSection: _ProductClassicOverviewSection.default,
  CatalogLayout: _CatalogLayout.default,
  ProductTabCollapseContent: _ProductTabCollapseContent.default
};
exports.components = components;
(0, _utils.register)(components);