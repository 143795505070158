import React from 'react'
import PropTypes from 'prop-types'
import {CatalogView} from "smarties-cms/dist/addon/Catalog/CatalogView";
import classnames from "classnames";
import {SearchForm} from "../catalog/CatalogView";
import {CSSTransition as Transition} from "react-transition-group";
import {CategoryCard} from "smarties-cms/dist/addon/Catalog/CategoryCard";
import {Link} from "smarties-cms/dist/components/common";
import {List} from 'smarties-cms/dist/components/list'
import {listRequestHelper, withSessionStorage} from 'smarties-cms/dist/connect'

// eslint-disable-next-line react/prop-types
const HeaderSearch = () => <SearchForm
  searchTimeout={0}
  target={"/catalog"}
  onSearchUsing={(value) => {
    document.location = `/catalog?q=${JSON.stringify(value)}`
  }}/>

@listRequestHelper({
  resource: "/api/catalog/category/list"
})
@withSessionStorage({
  storageKey: "header-categories",
  storageDefault: {}
})
class HeaderCatalog extends React.PureComponent {
  static propTypes = {
    activeNode: PropTypes.object,
    setCategoryFilter: PropTypes.func,
    success: PropTypes.bool,
    setStorageItem: PropTypes.func,
    getStorageItem: PropTypes.func,
    data: PropTypes.array,
    total: PropTypes.number,
  }

  constructor (props) {
    super(props)

    const categoryId = props.activeNode.children[0].category
    const items = props.getStorageItem(categoryId, {
      data: [],
      total: 0
    })

    this.state = {
      ...items,
      categoryFilter: categoryId
    }
  }

  componentDidUpdate({ success: prevSuccess }) {
    if (prevSuccess !== this.props.success && this.props.success) {
      const nextState = {
        data: this.props.data,
        total: this.props.total
      }

      this.props.setStorageItem(this.state.categoryFilter, nextState)
      this.setState(nextState)
    }
  }

  setCategoryFilter = (categoryFilterId, onFilterBy) => (e) => {
    e.preventDefault()
    onFilterBy({"parent.id": categoryFilterId})

    const items = this.props.getStorageItem(categoryFilterId, {
      data: [],
      total: 0
    })

    this.setState({
      ...items,
      categoryFilter: categoryFilterId
    })
  }

  goToCategory = ({ link }) => () => {
    document.location = link
  }


  render() {
    const {
      activeNode,
      // eslint-disable-next-line react/prop-types
      // eslint-disable-next-line react/prop-types
      loading,
      // eslint-disable-next-line react/prop-types
      success,
      // eslint-disable-next-line react/prop-types
      onFetchList,
    } = this.props

    const {
      data = [],
      total,
      categoryFilter
    } = this.state

    return (<CatalogView
      total={this.props.total || total}
      limits={[48]}
      range={[0,47]}
      data={this.props.data || data}
      loading={loading}
      success={success}
      onFetchList={onFetchList}
      listComponent={List}
      filtersComponent={({onFilterBy, filter}) => (<div className="navbar-submenu-filters nav col-12">
        {
          activeNode.children.slice(0, 2).map(({name, category, id}) => (<div className="nav-item" key={id}>
            <a href="#" className={classnames("nav-link", {
              active: Number((filter || {})["parent.id"]) === category
            })} onClick={this.setCategoryFilter(category, onFilterBy)}>{name}</a>
          </div>))
        }
      </div>)}
      bottomFiltersComponent={HeaderSearch}
      isPageable={false}
      enablePerPageLimitsSelector={false}
      fetchOnMount
      filter={{
        "parent.id": categoryFilter
      }}
      scrollToElement={null}
      ignoreGridStorage
      allowedGridColumns={[4, 3, 2]}
      gridColumns={4}
      recordComponent={(props) => (<Transition
        timeout={100}
        in={props.hovered}
        classNames="card">
        {
          (state) => <CategoryCard {...props} className={state} onClick={this.goToCategory(props)}/>
        }
      </Transition>)}
      gridSelectorComponent={null}
      extraItemProps={{
        placeholder: {
          width: 455,
          height: 256,
          className: 'placeholder-16-to-9'
        }
      }}>
      {
        ({data = []}) => data.length > 0 && (<div className="list-wrapper-item">
          <Link className="card next-page-card" href={`catalog/${categoryFilter}`}>
            <span className="card-body">All products</span>
          </Link>
        </div>)
      }
    </CatalogView>)
  }
}

export default HeaderCatalog
