"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RegisteredProductsAside", {
  enumerable: true,
  get: function get() {
    return _RegisteredProductsAside.default;
  }
});
Object.defineProperty(exports, "ProductRepairProjectOrderedParts", {
  enumerable: true,
  get: function get() {
    return _ProductRepairProjectOrderedParts.default;
  }
});

var _RegisteredProductsAside = _interopRequireDefault(require("./RegisteredProductsAside"));

var _ProductRepairProjectOrderedParts = _interopRequireDefault(require("./ProductRepairProjectOrderedParts"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }