"use strict";

var _utils = require("smarties-cms/dist/utils");

var _view = require("./view");

var _components = require("./components");

var _OpenSuportCenterButton = _interopRequireDefault(require("../common/components/SupportCenter/OpenSuportCenterButton"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _utils.register)({
  SupportTicketView: _view.SupportTicketView,
  SupportTicketFiles: _components.SupportTicketFiles,
  SupportTicketCommit: _view.SupportTicketCommit,
  SupportTicketDetails: _components.SupportTicketDetails,
  Navbar: _components.Navbar,
  OpenSupportCenterButton: _OpenSuportCenterButton.default
});