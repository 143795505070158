"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ShareButtonsWrapper = void 0;

require("core-js/modules/es6.object.assign");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.object.keys");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ShareButton = _interopRequireDefault(require("./ShareButton"));

var _classnames = _interopRequireDefault(require("classnames"));

var _ContextProvider = require("smarties-cms/dist/utils/ContextProvider");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var ShareButtonsWrapper = function ShareButtonsWrapper(_ref) {
  var _ref$component = _ref.component,
      Component = _ref$component === void 0 ? 'div' : _ref$component,
      _ref$networks = _ref.networks,
      networks = _ref$networks === void 0 ? [{
    network: 'Facebook',
    icon: 'fab fa-facebook-square'
  }] : _ref$networks,
      className = _ref.className,
      props = _objectWithoutProperties(_ref, ["component", "networks", "className"]);

  return _react.default.createElement(Component, {
    className: (0, _classnames.default)(className, 'share-nav', 'nav')
  }, networks.map(function (network, index) {
    return _react.default.createElement(_ShareButton.default, _extends({}, network, props, {
      className: "nav-item",
      key: index
    }));
  }));
};

exports.ShareButtonsWrapper = ShareButtonsWrapper;
ShareButtonsWrapper.propTypes = {
  networks: _propTypes.default.array,
  className: _propTypes.default.string,
  component: _propTypes.default.oneOfType([_propTypes.default.func, _propTypes.default.string])
};

var _default = (0, _ContextProvider.withAppContext)()(ShareButtonsWrapper);

exports.default = _default;