import React from 'react'
import PropTypes from 'prop-types'

class FooterBefore extends React.Component {
  static propTypes = {
    // Phone numbers set
    before: PropTypes.array,
  }

  render() {
    const {before} = this.props
    return (<div className="footer-numbers">
      {
        before.map(({name, value}, index) => (<div className="footer-number" key={index}>
          <h5 className="footer-number-title">
            {name}
          </h5>
          <a href={`tel:${value.replace(/\s/,'')}`} className="footer-number-value">
            {value}
          </a>
        </div>))
      }
    </div>)
  }
}

export default FooterBefore
