"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CarouselCaptions = exports.CarouselCaption = void 0;

require("core-js/modules/es6.object.assign");

require("core-js/modules/es6.string.link");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _common = require("smarties-cms/dist/components/common");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var CarouselCaption = function CarouselCaption(_ref) {
  var title = _ref.title,
      description = _ref.description,
      _ref$active = _ref.active,
      active = _ref$active === void 0 ? false : _ref$active,
      _ref$link = _ref.link,
      link = _ref$link === void 0 ? undefined : _ref$link,
      _ref$displaySeeAll = _ref.displaySeeAll,
      displaySeeAll = _ref$displaySeeAll === void 0 ? false : _ref$displaySeeAll,
      _ref$displaySeeAllTex = _ref.displaySeeAllText,
      displaySeeAllText = _ref$displaySeeAllTex === void 0 ? "See more" : _ref$displaySeeAllTex;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)("carousel-custom-caption", {
      active: active,
      'with-link': displaySeeAll && typeof link !== 'undefined' && link !== null
    }),
    onClick: function onClick() {
      if (!displaySeeAll) {
        document.location = link;
      }
    }
  }, _react.default.createElement("div", {
    className: "carousel-caption-title"
  }, title), description ? _react.default.createElement("div", {
    className: "carousel-caption-text",
    dangerouslySetInnerHTML: {
      __html: description
    }
  }) : null, displaySeeAll && typeof link !== 'undefined' && link !== null && _react.default.createElement(_common.Link, {
    href: link,
    className: "carousel-caption-link",
    icon: {
      placement: 'after',
      value: 'far fa-fw fa-chevron-right'
    }
  }, displaySeeAllText));
};

exports.CarouselCaption = CarouselCaption;
CarouselCaption.propTypes = {
  title: _propTypes.default.string.isRequired,
  description: _propTypes.default.string,
  active: _propTypes.default.bool,
  displaySeeAll: _propTypes.default.bool
};

var CarouselCaptions = function CarouselCaptions(_ref2) {
  var _ref2$items = _ref2.items,
      items = _ref2$items === void 0 ? [] : _ref2$items,
      activeIndex = _ref2.activeIndex,
      _ref2$className = _ref2.className,
      className = _ref2$className === void 0 ? '' : _ref2$className,
      _ref2$captionComponen = _ref2.captionComponent,
      CaptionComponent = _ref2$captionComponen === void 0 ? CarouselCaption : _ref2$captionComponen,
      _ref2$displaySeeAll = _ref2.displaySeeAll,
      displaySeeAll = _ref2$displaySeeAll === void 0 ? false : _ref2$displaySeeAll,
      displaySeeAllText = _ref2.displaySeeAllText;
  return items.length ? _react.default.createElement("div", {
    className: (0, _classnames.default)("carousel-custom-captions", className)
  }, items.map(function (item, index) {
    return _react.default.createElement(CaptionComponent, _extends({}, item, {
      displaySeeAllText: displaySeeAllText,
      displaySeeAll: displaySeeAll,
      active: index === activeIndex,
      key: index
    }));
  })) : null;
};

exports.CarouselCaptions = CarouselCaptions;
CarouselCaptions.propTypes = {
  items: _propTypes.default.array,
  className: _propTypes.default.string,
  activeIndex: _propTypes.default.number,
  captionComponent: _propTypes.default.func,
  displaySeeAll: _propTypes.default.bool
};