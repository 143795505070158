"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactstrap = require("reactstrap");

var _utils = require("smarties-cms/dist/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var ProjectStatusIconWidget = function ProjectStatusIconWidget(_ref) {
  var value = _ref.value,
      _ref$options$items = _ref.options.items,
      items = _ref$options$items === void 0 ? [] : _ref$options$items;
  var item = items.find(function (item) {
    return item.value === value;
  });

  if (!item) {
    return null;
  }

  var icon = '',
      name = '';

  if (Array.isArray(item.name)) {
    var _item$name = _slicedToArray(item.name, 2);

    icon = _item$name[0];
    name = _item$name[1];
  } else {
    icon = item.name;
  }

  var id = (0, _utils.uniqId)('project-status-icon');
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("i", {
    className: icon,
    id: id
  }), _react.default.createElement(_reactstrap.UncontrolledTooltip, {
    target: id
  }, name));
};

ProjectStatusIconWidget.propTypes = {
  value: _propTypes.default.number,
  options: _propTypes.default.object
};
var _default = ProjectStatusIconWidget;
exports.default = _default;