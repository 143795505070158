"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BlogVideosList", {
  enumerable: true,
  get: function get() {
    return _BlogVideosList.default;
  }
});
Object.defineProperty(exports, "BlogVideoPost", {
  enumerable: true,
  get: function get() {
    return _BlogVideoPost.default;
  }
});

var _BlogVideosList = _interopRequireDefault(require("./BlogVideosList"));

var _BlogVideoPost = _interopRequireDefault(require("./BlogVideoPost"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }