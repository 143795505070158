"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridSelectorList = exports.GridSelector = void 0;

require("core-js/modules/es6.object.assign");

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var GridSelector = function GridSelector(_ref) {
  var columns = _ref.columns,
      icon = _ref.icon,
      onSelect = _ref.onSelect;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)("categories-grid", "columns-".concat(columns)),
    onClick: function onClick(e) {
      e.persist();
      onSelect(e, columns);
    }
  }, _react.default.createElement("a", {
    href: "#",
    className: "categories-grid-toggle"
  }, _react.default.createElement("i", {
    className: (0, _classnames.default)("fa", icon)
  })));
};

exports.GridSelector = GridSelector;
GridSelector.propTypes = {
  columns: _propTypes.default.number.isRequired,
  icon: _propTypes.default.string.isRequired,
  onSelect: _propTypes.default.func.isRequired
};

var GridSelectorList = function GridSelectorList(_ref2) {
  var selectors = _ref2.selectors,
      className = _ref2.className,
      Selector = _ref2.selectorComponent,
      onSelect = _ref2.onSelect,
      children = _ref2.children;
  return _react.default.createElement("div", {
    className: (0, _classnames.default)(className, 'categories-grids')
  }, selectors.map(function (selector, index) {
    return _react.default.createElement(Selector, _extends({}, selector, {
      onSelect: onSelect,
      key: index
    }));
  }), children);
};

exports.GridSelectorList = GridSelectorList;
GridSelectorList.propTypes = {
  selectors: _propTypes.default.array,
  className: _propTypes.default.string,
  selectorComponent: _propTypes.default.func,
  onSelect: _propTypes.default.func,
  children: _propTypes.default.node
};
GridSelectorList.defaultProps = {
  selectors: [{
    columns: 2,
    icon: 'fa-th-large'
  }, {
    columns: 3,
    icon: 'fa-th'
  }],
  selectorComponent: GridSelector
};