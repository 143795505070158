"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.link");

var _react = _interopRequireDefault(require("react"));

var _common = require("smarties-cms/dist/components/common");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BlogPostReactions = function BlogPostReactions(props) {
  var link = props.link,
      total = props.discussion.total;
  return _react.default.createElement("div", {
    className: "blog-post-reactions"
  }, _react.default.createElement("span", {
    className: "blog-post-reaction blog-post-reaction-likes"
  }, _react.default.createElement("span", {
    className: "blog-post-reaction-icon"
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-thumbs-up"
  })), _react.default.createElement("span", {
    className: "blog-post-reaction-text"
  }, "26 likes")), _react.default.createElement(_common.Link, {
    href: "".concat(link, "#comments"),
    className: "blog-post-reaction blog-post-reaction-comments"
  }, _react.default.createElement("span", {
    className: "blog-post-reaction-icon"
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-comments"
  })), _react.default.createElement("span", {
    className: "blog-post-reaction-text"
  }, total > 0 ? "".concat(total, " comments") : "0 comments")));
};

var _default = BlogPostReactions;
exports.default = _default;