import React from 'react'
import PropTypes from 'prop-types'
import { DropdownMenu, DropdownItem } from 'reactstrap'
import { AccountInfo } from "./AsideAccountInfo";

export default class UserDropdownMenu extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object.isRequired
  }

  render () {
    const {
      children,
      user,
      ...props
    } = this.props

    return (<DropdownMenu {...props}>
      <DropdownItem tag={AccountInfo} user={user}/>
      { children }
    </DropdownMenu>)
  }
}
