"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductGallery = void 0;

require("core-js/modules/es6.object.assign");

var _react = _interopRequireDefault(require("react"));

var _gallery = require("smarties-cms/dist/components/gallery");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var ProductGallery = function ProductGallery(props) {
  return _react.default.createElement(_gallery.Gallery, _extends({
    pause: false,
    className: "product-gallery"
  }, props));
};

exports.ProductGallery = ProductGallery;
ProductGallery.propTypes = _gallery.Gallery.propTypes;