"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _reactstrap = require("reactstrap");

var _utils = require("smarties-cms/dist/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var BlogPostTags = function BlogPostTags(props) {
  var _props$type = props.type,
      type = _props$type === void 0 ? 'blogarticle' : _props$type,
      typeReadable = props.typeReadable,
      _props$tags = props.tags,
      tags = _props$tags === void 0 ? [] : _props$tags;
  var tagsId = (0, _react.useRef)((0, _utils.uniqId)('blog-post-tags'));
  var typeId = (0, _react.useRef)((0, _utils.uniqId)('blog-post-type'));
  return _react.default.createElement("span", {
    className: "blog-post-tags"
  }, _react.default.createElement("span", {
    className: "blog-post-tag blog-post-tag-type",
    id: typeId.current
  }, _react.default.createElement("span", {
    className: "blog-post-tag-text"
  }, typeReadable), _react.default.createElement("span", {
    className: "blog-post-tag-icon"
  }, _react.default.createElement("i", {
    className: (0, _classnames.default)("fas fa-fw", {
      "fa-blog": type === 'blogarticle',
      "fa-video": type === 'blogvideoarticle',
      "fa-file-alt": type === 'blogmanualarticle'
    })
  }))), tags.length > 0 && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("span", {
    className: "blog-post-tag blog-post-tag-tags",
    id: tagsId.current
  }, _react.default.createElement("span", {
    className: "blog-post-tag-icon"
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-tags"
  }))), _react.default.createElement(_reactstrap.UncontrolledTooltip, {
    target: tagsId.current,
    placement: "left"
  }, tags.map(function (_ref) {
    var name = _ref.name;
    return name;
  }).join(', '))));
};

var _default = BlogPostTags;
exports.default = _default;