import React from 'react'
import PropTypes from 'prop-types'
import {CartActionTopNavbar} from 'smarties-cms/dist/addon/Shop/client/components/cart'

class HeaderCart extends React.PureComponent {
  static propTypes = {
    layout: PropTypes.string
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (<li className="nav-item">
      <CartActionTopNavbar
        layout={this.props.layout || 'pricing'}
        forceNavbarDisplay/>
    </li>)
  }
}

export default HeaderCart
