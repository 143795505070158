import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'smarties-cms/dist/components/common'
import classnames from 'classnames'

class CategoryCardAside extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    items: PropTypes.array,
    level: PropTypes.number,
    active: PropTypes.bool
  }

  static defaultProps = {
    items: [],
    level: 0
  }

  renderChild = (props) => (<li className="nav-item">
      <CategoryCardAside {...props} level={this.props.level+1}/>
    </li>)

  render () {
    const { name,  items = [], link, level, active } = this.props

    return (<section className={classnames("category-nav", `level-${level}`, {
      active
    })}>
      <Link href={link} className={classnames("category-title nav-link", {
        active,
        'has-children': items.length > 0
      })}>{name}</Link>
      <nav className="category-children nav flex-column">
        {
          items && items.map(this.renderChild)
        }
      </nav>
    </section>)
  }
}

export default CategoryCardAside