"use strict";

var _utils = require("smarties-cms/dist/utils");

var _NewMessagesWidget = _interopRequireDefault(require("../common/dashboard/widgets/NewMessagesWidget"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _utils.register)({
  DashboardWidget: _NewMessagesWidget.default
});