import React from 'react'
import PropTypes from 'prop-types'
import {DynamicComponent} from 'smarties-cms/dist/utils'
import {
  StickyObserver,
  IntersectionSubscriber,
  ResponsiveProvider,
  Responsive
} from 'smarties-cms/dist/components/common'
import classnames from 'classnames'
import {Preview} from 'smarties-cms/dist/components/wysiwyg'

export default class ProductOverview extends React.Component {
  static propTypes = {
    product: PropTypes.object
  }

  render() {
    const {product} = this.props
    return (<ResponsiveProvider>
      <StickyObserver className="product-overview-section col-12">
        <Preview className="product-overview-section-wysiwyg" value={product.description}/>

        <Responsive showOn="md-down">
          <IntersectionSubscriber className="product-overview-section-buttons-wrapper sticky-footer">
            {
              ({stuck}) => (<div className={classnames('product-overview-section-buttons', {stuck})}>
                <DynamicComponent
                  renderChildrenAsArray
                  componentName="ProductOverviewButtonsMobile"
                  fallbackComponent={() => null}
                  product={product}/>
              </div>)
            }
          </IntersectionSubscriber>
        </Responsive>
      </StickyObserver>
    </ResponsiveProvider>)
  }
}

