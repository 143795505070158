"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _BlogPostAuthor = _interopRequireDefault(require("./BlogPostAuthor"));

var _common = require("smarties-cms/dist/components/common");

var _BlogPostTags = _interopRequireDefault(require("./BlogPostTags"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BlogPostAside = function BlogPostAside(props) {
  var creator = props.creator,
      type = props.type,
      typeReadable = props.typeReadable,
      creationTime = props.creationTime,
      _props$tags = props.tags,
      tags = _props$tags === void 0 ? [] : _props$tags;
  return _react.default.createElement("aside", {
    className: "blog-post-aside"
  }, _react.default.createElement(_BlogPostAuthor.default, creator, _react.default.createElement("span", {
    className: "blog-post-date"
  }, _react.default.createElement("span", {
    className: "blog-post-date-icon"
  }, _react.default.createElement("i", {
    className: "fas fa-fw fa-clock"
  })), _react.default.createElement(_common.DateTime, {
    targetFormat: "Do MMMM YYYY"
  }, creationTime))), _react.default.createElement(_BlogPostTags.default, {
    typeReadable: typeReadable,
    type: type,
    tags: tags
  }));
};

BlogPostAside.propTypes = {
  creator: _propTypes.default.object,
  type: _propTypes.default.string,
  tags: _propTypes.default.array
};
var _default = BlogPostAside;
exports.default = _default;