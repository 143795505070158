"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _common = require("smarties-cms/dist/components/common");

var _BlogPostUpgrade = _interopRequireDefault(require("./BlogPostUpgrade"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BlogSubscriptionsBanner = function BlogSubscriptionsBanner(props) {
  return _react.default.createElement("div", {
    className: "blog-subscriptions-banner"
  }, _react.default.createElement(_common.Link, {
    icon: {
      placement: "after",
      value: "fas fa-fw fa-chevron-right"
    },
    href: "subscriptions/choose"
  }, "Read more"), _react.default.createElement(_BlogPostUpgrade.default, {
    required: true
  }));
};

var _default = BlogSubscriptionsBanner;
exports.default = _default;