import React from 'react'
import PropTypes from 'prop-types'
import {NotificationsDropdown} from 'smarties-cms/dist/components/notifications'
import {connectUserDetails} from 'smarties-cms/dist/connect/user'

@connectUserDetails()
class HeaderNotifications extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string,
    counterOnly: PropTypes.bool
  }

  static defaultProps = {
    user: {},
    counterOnly: false,
    className: 'navbar-nav'
  }

  render() {
    if (!this.props.user.id) {
      return null
    }

    return (<div className={`${this.props.className} nav-notifications`}>
      <NotificationsDropdown/>
    </div>)
  }
}

export default HeaderNotifications
