"use strict";

var _utils = require("smarties-cms/dist/utils");

var _layout = require("./layout");

var _components = require("./components");

(0, _utils.register)({
  ProjectView: [_layout.ProjectView, true],
  ProjectDetails: _components.ProjectDetails,
  ProjectTimeline: _components.ProjectTimeline
});